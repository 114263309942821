import { createApp } from "vue";
import { ref } from 'vue';
import Faq from "../../components/Faq.vue";
import "../style.css";

const app = createApp({
  data() {
    return {
      menuOpen: ref(false),
    };
  }
});

app.component("Faq", Faq);
app.mount("#app");