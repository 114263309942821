
<template>
    <section>
        <div class="container mx-auto max-w-lg md:max-w-4xl  mx-auto my-8 py-2" v-for="faq in localFaqs">
            <div class="bg-slate-50 pl-20  rounded-full  shadow-xl cursor-pointer">
                <div class="flex justify-between items-center  cursor-pointer" @click="faq.isactive = !faq.isactive">
                    <h3 :class="[faq.isactive ? 'text-blue-500' : 'text-gray-600', 'text-base  md:text-2xl', 'mr-8']">{{
                        faq.title }}
                    </h3>
                    <span
                        :class="[faq.isactive ? 'text-blue-500' : 'text-gray-600', 'font-bold text-3xl md:text-7xl', 'mr-8']">&plus;</span>

                </div>

            </div>
            <div class="answer-1 mt-2" v-if="faq.isactive">
                <p class="text-gray-700 ml-14 text-sm md:text-base max-w-3xl">{{ faq.content }}</p>
            </div>
        </div>
    </section>
</template>

<script setup>

import { ref } from 'vue'
const props = defineProps(['faqs']);
const localFaqs = ref(props.faqs)

</script>
